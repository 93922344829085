import React from 'react';
import ReactDOM from 'react-dom';

// styling
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import App from './App';
import './data';

ReactDOM.render(<App />, document.getElementById('root'));
